.text {
  color: var(--Opaque-Grays-O-Gray-5, #272727);
  text-align: center;
  font-feature-settings: 'case' on;
  font-family: NewTheinhardt;
  font-size: 10.103px;
  font-style: normal;
  font-weight: 500;
  line-height: 13.856px; /* 137.143% */
}
