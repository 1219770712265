@import '~ethos-design-system/src/components/Media/Media.scss';

@mixin for-phone-and-laptop-mid {
  @media (max-width: $laptop-range-mid) {
    @content;
  }
}

@mixin custom-md {
  @media (max-width: 820px) {
    @content;
  }
}

.mobileOnly {
  display: none;
  width: 100%;

  @include custom-md {
    display: block;
  }
}

.imageContainer {
  @media (min-width: 768px) {
    img {
      height: 421px;
    }
  }
  @media (min-width: 1024px) {
    img {
      height: 521px;
    }
  }
  @media (min-width: 1440px) {
    img {
      transform: translate(100px, 0);
    }
  }
}

.wrapper {
  position: relative;
}

.desktopOnly {
  display: block;
  width: 100%;
  max-width: 545px;
  @include custom-md {
    display: none;
  }
}

.finalExpense {
  position: relative;
  background: linear-gradient(45deg, #daefe3 0%, #c0d8d5 100%);
  display: flex;
  justify-content: center;
  max-height: var(--form-height, 698px);

  @include custom-md {
    display: block;
    max-height: unset;

    br {
      display: none;
    }
  }

  .container {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    padding: 120px 80px 0;

    @include custom-md {
      padding-top: 80px;
    }

    @media (min-width: 600px) and (max-width: 899px) {
      padding-left: 72px;
      padding-right: 72px;
    }
    @media (max-width: 599px) {
      padding-left: 18px;
      padding-right: 18px;
    }
  }

  .mainContent {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .header {
    font-family: Cambon;
    font-size: 60px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -0.02em;
    margin-bottom: 40px;
    max-width: 668px;

    p {
      margin: 0;
    }
    @include custom-md {
      margin-bottom: 24px;
    }

    @media (max-width: 1100px) {
      font-size: 40px;
      font-weight: 700;
      line-height: 52px;
      letter-spacing: -0.02em;
    }
  }

  .subText {
    max-width: 510px;
    font-family: Theinhardt;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 185px;

    @media (max-width: 1100px) {
      max-width: initial;
      margin-bottom: 32px;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
    }

    @media (max-width: 1300px) {
      margin-bottom: 100px;
    }
    @include custom-md {
      margin-bottom: 32px;
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  .trustImages {
    max-width: 510px;
    display: flex;
    align-items: center;
    gap: 42px;
    margin-bottom: 80px;

    .trustImageContainer {
      height: 24px;
    }

    @media (max-width: 900px) {
      .trustImageContainer {
        height: 20px;
        &:nth-child(n + 3):not(:last-child) {
          display: none;
        }
      }
    }

    @include custom-md {
      max-width: initial;
      background-color: white;
      gap: 32px;
      .trustImageContainer {
        height: 20px;
        &:nth-child(n + 3):not(:last-child) {
          display: none;
        }
      }
      padding: 0 18px;

      justify-content: center;
      margin-bottom: 48px;
      padding-top: 16px;
    }
  }

  .bgImage {
    position: absolute;
    max-width: 500px;
    width: 100%;
    bottom: 0;
    right: calc(50% - 210px);
    @include for-phone-and-laptop-mid {
      display: none;
    }
    @media (max-width: 1300px) {
      display: none;
    }
  }
}
