@mixin for-mid-tablet-and-up {
  @media (min-width: 820px) {
    @content;
  }
}

.terms {
  font-family: Theinhardt;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
}

.overviewWrapper {
  align-items: flex-start;
  order: 2;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  align-self: flex-start;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: white;
  padding: 32px;
  box-shadow: 0px -4px 6px -2px rgba(16, 24, 40, 0.03),
    0px -12px 16px -4px rgba(16, 24, 40, 0.08);

  @include for-mid-tablet-and-up {
    order: 1;
    width: auto;
    max-width: 520px;
    border-radius: 16px;
    padding: 48px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 1000px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: 600px) and (max-width: 819px) {
    padding-left: 72px;
    padding-right: 72px;
  }

  @media (max-width: 599px) {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.overviewText {
  color: #000;

  font-family: NewTheinhardt;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  @include for-mid-tablet-and-up {
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.24px;
  }
}
