@import '~ethos-design-system/src/components/Media/Media.scss';
@import '../../../Mixins';

.loadingForm {
  justify-content: center;
  align-items: center;
  display: flex;
  // needs to match the default height/margin of the infoForm
  height: 460px;
}
