@import '~ethos-design-system/src/components/Media/Media.scss';

.sliderWrapper {
  [data-reach-slider-marker] {
    background: var(--White);
  }

  [data-reach-slider-input][data-orientation='horizontal'] {
    height: 2px;
  }
  [data-reach-slider-range] {
    background-color: var(--secondary-spearmint-75);
  }

  [data-reach-slider-track] {
    background-color: var(--secondary-spearmint-15);
  }
  [data-reach-slider-track-highlight],
  [data-reach-slider-handle] {
    cursor: grab;
    background: #056257;
    width: 40px;
    height: 27px !important;
    border-radius: 200px;
    &:before {
      content: '||';
      color: var(--White);
      font-family: Theinhardt;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.ctaButtonVariant2 {
  position: fixed;
  width: 100%;
  // center the button
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background: var(--Color-Base-White, #fff);
  padding: 16px;

  box-shadow: 0px -2px 4px -2px rgba(16, 24, 40, 0.06),
    0px -4px 8px -2px rgba(16, 24, 40, 0.1);

  @include for-tablet-and-up {
    position: static;
    margin-top: 32px;
    box-shadow: none;
    left: auto;
    transform: none;
    padding: 0;
  }
}
.ctaButton {
  margin-top: 32px;
}
