@import '~ethos-design-system/src/components/Media/Media.scss';
@import '../Mixins';

.estimateForm {
  @include for-phone-and-small-tablet {
    padding: var(--Space-48) 0 0 0;
  }

  @include for-large-tablet-only {
    padding: var(--Space-48);
  }

  @include for-laptop-only {
    padding: var(--Space-56) var(--Space-64);
  }

  &.boxed {
    @include for-desktop-only {
      padding: var(--Space-64) var(--Space-80);
    }
  }

  &.hero {
    padding: 0;
    @include for-laptop-only {
      padding: var(--Space-24);
    }

    @include for-desktop-only {
      padding: 0 var(--Space-48) 0 0;
    }

    @include for-large-tablet-only {
      .rangeFormInner {
        margin-bottom: var(--Space-32);
      }
    }
  }

  &.moss {
    background-color: var(--BrandMoss);
    .price {
      color: var(--BrandForest);
    }
  }

  &.duckegg {
    background-color: var(--BrandDuckegg);
    .price {
      color: var(--BrandForest);
    }
  }

  &.white {
    background-color: var(--White);
    .price {
      color: var(--BrandSalamander);
    }
    &.boxed {
      @include for-large-tablet-and-up {
        border: 1px solid var(--GrayPrimary--opaque);
      }
    }
  }

  &.gray {
    background-color: var(--GrayLightHover--opaque);
    .price {
      color: var(--BrandForest);
    }
  }

  &.buttercup {
    background-color: var(--BrandButtercup);
    .price {
      color: var(--BrandSalamander);
    }
    .infoForm .fieldsV1 button[aria-checked='true'],
    .infoForm .fieldsV1 button:active,
    .infoForm .fieldsV2 button[aria-checked='true'],
    .infoForm .fieldsV2 button:active {
      background: var(--GrayPrimary--translucent);
    }
  }

  &.hideRangeForm {
    .rangeForm {
      display: none;
    }
  }

  &.hideInfoForm {
    .infoForm {
      display: none;
    }
  }

  &.hero {
    padding-left: 0;

    .infoFormInner,
    .rangeFormInner {
      flex-direction: column;

      .copy {
        width: 100%;
        max-width: none;
        margin-bottom: var(--Space-48);
        margin-right: 0;
      }
    }
    .disclaimer {
      color: var(--GraySecondary--opaque);
      display: flex;
      align-items: center;

      @include for-phone-and-small-tablet {
        display: none;
      }
    }
    @include for-large-tablet-and-up {
      .infoForm .button,
      .infoForm .disclaimer {
        width: 50%;
      }

      .infoForm .disclaimer {
        max-width: 267px;
      }

      .rangeForm .disclaimer {
        width: 33%;
        margin-top: var(--Space-24);
      }

      .rangeForm .heroButtonContainer {
        display: flex;
        justify-content: space-between;
      }

      .rangeForm .buttons {
        max-height: var(--Space-56);
      }
    }
  }
}

.editAnswers {
  width: 100%;
  margin-bottom: 10px;

  button {
    color: var(--GraySecondary--opaque);
  }
}

.label {
  color: var(--GraySecondary--opaque);
  margin-bottom: var(--Space-4);
}

.value {
  margin-bottom: var(--Space-8);
}

.copy {
  width: 100%;
  margin-bottom: var(--Space-48);

  @include for-tablet-only {
    margin-bottom: var(--Space-24);
  }

  @include for-desktop-only {
    max-width: 250px;
    margin-right: var(--Space-32);
    margin-bottom: 0;
  }

  @include for-large-desktop-only {
    max-width: 400px;
    margin-right: var(--Space-80);
  }

  .subHeading {
    margin-top: var(--Space-16);
  }
}

.infoFormInner,
.rangeFormInner {
  display: flex;
  flex-direction: column;

  @include for-desktop-only {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.rangeForm {
  .estimateCopy {
    width: 100%;

    @include for-phone-and-small-tablet {
      margin-bottom: var(--Space-4);
    }

    @include for-large-tablet-and-up {
      width: calc(50% - 20px);
    }
  }

  .copy {
    @include for-phone-only {
      margin-bottom: 24px;
    }
  }

  .rangeFormFieldsContainer {
    width: 100%;
  }

  .rangeFormFields {
    @include for-large-tablet-and-up {
      .sliders {
        display: flex;
        justify-content: space-between;

        .slider {
          width: calc(50% - 20px);
        }
      }
    }
  }

  .prices {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: var(--Space-32);

    @include for-phone-and-small-tablet {
      margin-bottom: var(--Space-24);
    }

    @include for-large-tablet-and-up {
      flex-direction: row;
    }

    .ranges {
      display: flex;
      @include for-phone-and-small-tablet {
        margin-top: var(--Space-8);
      }

      width: calc(50% - 20px);

      .price {
        width: 120px;
        display: inline-block;
        &:first-child {
          margin-right: var(--Space-16);
        }
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column-reverse;
    margin-top: var(--Space-40);

    @include for-phone-and-small-tablet {
      margin-top: var(--Space-32);
    }

    @include for-large-tablet-and-up {
      flex-direction: row;
      margin-top: var(--Space-24);
      justify-content: flex-end;
    }

    button:first-child {
      display: none;

      @include for-large-tablet-and-up {
        display: flex;
        margin-right: var(--Space-16);
      }
    }

    button:nth-child(2) {
      display: none;

      @include for-phone-and-small-tablet {
        display: flex;
        margin-top: var(--Space-16);
        margin-bottom: var(--Space-24);

        svg {
          margin-right: 10px;
        }
      }
    }

    button:last-child {
      @include for-phone-and-small-tablet {
        margin-right: auto;
      }
    }
  }

  @include for-large-tablet-and-up {
    .sliders {
      display: flex;
      justify-content: space-between;

      .slider {
        width: calc(50% - 20px);
      }
    }
  }

  .slider:first-child {
    margin-bottom: var(--Space-24);
  }

  [data-reach-slider-track-highlight] {
    background: var(--GrayPrimary--opaque);
  }

  [data-reach-slider-handle] {
    background: var(--GrayPrimary--opaque);
    border: none;
    width: 15px;
    height: 15px;
    border-radius: 15px;
  }

  [data-reach-slider-track] {
    background: var(--GrayStrokeAndDisabled--opaque);
  }

  [data-reach-slider-input][data-orientation='horizontal'] {
    width: 100%;
    height: 1px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  [data-reach-slider-marker],
  [data-reach-slider-marker][data-state='under-value'] {
    background: var(--GrayPrimary--opaque);
    opacity: 1;
    border: none;
    width: 7px;
    height: 7px;
    border-radius: 7px;

    &[data-state='over-value'] {
      background: var(--GrayStrokeAndDisabled--opaque);
    }
  }
}

.infoForm {
  .fieldsV1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include for-large-tablet-and-up {
        flex-wrap: nowrap;
        margin-bottom: var(--Space-16);
      }
    }

    .field {
      @include for-phone-and-small-tablet {
        margin-bottom: var(--Space-16);

        &:not(.health) {
          button {
            min-width: calc(50% - var(--Space-4));
          }
        }

        &.health,
        &.estimatedCredit {
          button {
            min-width: 80px;
            padding-right: 6px;
            padding-left: 6px;
          }
        }
      }

      @include for-large-tablet-and-up {
        &:not(.smoker) button,
        input[type='tel'] {
          min-width: 100px;
        }

        &.smoker button {
          min-width: 61px;
          padding-left: 6px;
          padding-right: 6px;
          width: 100%;
        }

        &:last-child {
          min-width: 167px;
          max-width: 167px;
        }
      }

      @include for-small-desktop-only {
        &:last-child {
          min-width: 135px;
          max-width: 135px;
        }
      }

      button,
      input[type='tel'] {
        font-size: 17.5px;
        line-height: 24px;
      }
    }

    .gender {
      @include for-phone-and-small-tablet {
        width: 100%;
      }

      @include for-large-tablet-only {
        margin-right: var(--Space-24);
      }

      @include for-large-tablet-and-up {
        flex-grow: 1;
      }

      @include for-laptop-only {
        margin-right: var(--Space-32);
      }

      @include for-small-desktop-only {
        margin-right: var(--Space-24);
      }

      @include for-large-desktop-only {
        margin-right: var(--Space-32);
      }
    }

    .birthDate {
      @include for-phone-and-small-tablet {
        width: calc(50% - var(--Space-4));
      }
    }

    .zipCode {
      @include for-phone-and-small-tablet {
        width: calc(50% - var(--Space-4));
      }

      @include for-large-tablet-only {
        margin-left: var(--Space-24);
      }

      @include for-laptop-only {
        margin-left: var(--Space-32);
      }

      @include for-small-desktop-only {
        margin-left: var(--Space-24);
      }

      @include for-large-desktop-only {
        margin-left: var(--Space-32);
      }
    }

    .health {
      width: 100%;

      @include for-large-tablet-only {
        max-width: 73%;
      }
    }

    .smoker {
      width: 100%;

      button {
        width: calc(50% - var(--Space-4));
      }

      @include for-large-tablet-only {
        margin-left: var(--Space-24);
      }

      @include for-laptop-only {
        margin-left: var(--Space-32);
      }

      @include for-small-desktop-only {
        margin-left: var(--Space-24);
      }

      @include for-large-desktop-only {
        margin-left: var(--Space-32);
      }
    }

    .estimatedCredit {
      width: 100%;
    }
  }

  .fieldsV2 {
    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .lineBreak {
      flex-basis: 100%;
    }

    .field {
      margin-bottom: var(--Space-16);

      @include for-phone-and-tablet {
        &.gender {
          order: 1;
          width: 100%;

          button {
            min-width: calc(50% - var(--Space-4));
          }
        }

        &.health {
          order: 2;
          width: 100%;

          button {
            min-width: 80px;
            padding-right: 6px;
            padding-left: 6px;
          }
        }

        &.birthDate {
          order: 3;
          width: calc(50% - var(--Space-4));
        }

        &.zipCode {
          order: 4;
          width: calc(50% - var(--Space-4));
        }

        &.smoker {
          order: 5;
          width: 100%;

          button {
            min-width: calc(50% - var(--Space-4));
          }
        }

        &.estimatedCredit {
          order: 6;
          width: 100%;
        }
      }

      @include for-laptop-and-up {
        &.gender,
        &.smoker {
          flex-grow: 2;
          margin-right: var(--Space-16);
          min-width: 155px;
          max-width: calc(25% - var(--Space-16));

          button {
            min-width: calc(50% - var(--Space-4));
            padding-left: 6px;
            padding-right: 6px;
          }
        }

        &.health,
        &.estimatedCredit {
          flex-grow: 1;
          min-width: 270px;
          max-width: calc(49% - var(--Space-16));

          button {
            min-width: calc(33% - var(--Space-4));
            padding-left: 6px;
            padding-right: 6px;
          }
        }

        &.birthDate,
        &.zipCode {
          margin-left: var(--Space-16);
          min-width: 130px;
          max-width: calc(25% - var(--Space-16));
        }
      }

      @include for-small-desktop-only {
        &.birthDate,
        &.zipCode {
          width: 130px;
        }
      }

      button,
      input[type='tel'] {
        font-size: 17.5px;
        line-height: 24px;
      }
    }
  }

  .button {
    margin-top: var(--Space-24);

    @include for-phone-and-small-tablet {
      width: 100%;
      button {
        width: 100%;
      }
      margin-bottom: var(--Space-24);
    }

    @include for-tablet-and-up {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}

.disclaimer {
  color: var(--White);
}
