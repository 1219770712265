@import '~ethos-design-system/src/components/Media/Media.scss';
@import '../../EstimateWidget/Mixins.scss';

.hidden {
  display: none;
}

.estimateEditAnswerContainer {
  display: none;
  width: 180px;

  @include for-large-laptop-and-up {
    display: block;
  }

  .header {
    display: flex;
    margin-bottom: var(--Space-24);

    :first-child {
      margin-right: var(--Space-8);
    }

    button {
      text-decoration: underline;
      color: var(--GraySecondary--opaque);
    }
  }
}
