@import '~ethos-design-system/src/components/Media/Media.scss';

@mixin for-phone-and-laptop-mid {
  @media (max-width: $laptop-range-mid) {
    @content;
  }
}

@mixin for-mid-tablet-and-up {
  @media (min-width: 820px) {
    @content;
  }
}

.background {
  background: linear-gradient(45deg, #daefe3 0%, #c0d8d5 100%);
}

.container {
  max-width: 1440px;
  padding: 180px 100px 48px;
  margin: 0 auto;
  width: 100%;
  height: 100dvh;

  @include for-mid-tablet-and-up {
    padding-bottom: 20px;
  }

  @include for-phone-and-laptop-mid {
    padding: 96px 80px 48px;
    width: 100%;
    border-radius: 0;
  }

  @media (min-width: 600px) and (max-width: 899px) {
    padding-left: 72px;
    padding-right: 72px;
  }

  @media (max-width: 599px) {
    padding-left: 18px;
    padding-right: 18px;
  }
}
