@import '~ethos-design-system/src/components/Media/Media.scss';

// bespoke media queries to meet figma specs
$small-tablet-range-end: 767px;
$large-tablet-range-start: 768px;
$laptop-small-range-end: 1023px;
$large-laptop-range-start: 1024px;
$small-desktop-range-end: 1439px;
$large-desktop-range-start: 1440px;

@mixin for-phone-and-small-tablet {
  @media (max-width: $small-tablet-range-end) {
    @content;
  }
}
@mixin for-large-tablet-only {
  @media (min-width: $large-tablet-range-start) and (max-width: $tablet-range-end) {
    @content;
  }
}

@mixin for-large-tablet-and-up {
  @media (min-width: $large-tablet-range-start) {
    @content;
  }
}

@mixin for-large-tablet-and-laptop {
  @media (min-width: $large-tablet-range-start) and (max-width: $laptop-range-end) {
    @content;
  }
}

@mixin for-small-laptop-only {
  @media (min-width: $laptop-range-start) and (max-width: $laptop-small-range-end) {
    @content;
  }
}

@mixin for-large-laptop-and-up {
  @media (min-width: $large-laptop-range-start) {
    @content;
  }
}

@mixin for-small-desktop-only {
  @media (min-width: $desktop-range-start) and (max-width: $small-desktop-range-end) {
    @content;
  }
}

@mixin for-large-desktop-only {
  @media (min-width: $large-desktop-range-start) {
    @content;
  }
}
