@import '~ethos-design-system/src/components/Media/Media.scss';

@mixin custom-md {
  @media (max-width: $laptop-range-mid) {
    @content;
  }
}

@mixin custom-md {
  @media (max-width: 820px) {
    @content;
  }
}

@mixin for-tablet-and-laptop-mid {
  @media (min-width: $tablet-range-start) and (max-width: $laptop-range-mid) {
    @content;
  }
}

.container {
  max-width: 535px;
  min-width: 440px;
  padding: 48px;
  gap: 32px;
  border-radius: 16px;
  background: var(--base-white, #fff);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  @include custom-md {
    width: 100%;
    max-width: 100%;
    min-width: 0;
    padding: 32px 80px;
    border-radius: 0;
  }

  @media (max-width: 599px) {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.dropdownWrapper {
  label {
    font-family: NewTheinhardt;
    font-size: 28px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 16px;
  }
  [class^='StyledReactSelect__control'] {
    padding: 32px 24px;
    height: 80px;
  }
  [class^='StyledReactSelect__placeholder'] {
    font-family: NewTheinhardt;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  [class*='eds2-text-[--theme-error-emphasis'] {
    font-family: NewTheinhardt;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: var(--theme-error-emphasis, #ff0000);
  }
  [class^='StyledReactSelect__option'] {
    font-family: NewTheinhardt;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  [class^='StyledReactSelect__single-value'] {
    font-family: NewTheinhardt;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  svg {
    width: 40px;
    height: 40px;
  }
}

.button {
  margin-top: 93px;
  height: 72px;

  span {
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.01em;
  }

  @include custom-md {
    margin-top: 32px;
    & > div {
      justify-content: space-between;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
}
.backButton {
  background: none;
  border: none;
  padding: 0;
  margin-top: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 8px;
  span {
    font-family: NewTheinhardt;
    font-size: 20px;
    font-weight: 400;
    line-height: 14px;
  }
}

.finalExpenseRange {
  font-family: Cambon;
  font-size: 60px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -0.02em;
  text-align: left;

  @include custom-md {
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
  }
}
