@import '~ethos-design-system/src/components/Media/Media.scss';

// Form related styles
.formWrapper {
  @include for-tablet-only {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  @include for-laptop-and-up {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.form {
  label {
    color: #727272 !important;
    margin-bottom: var(--Space-4);
  }

  @include for-phone-only {
    :first-child {
      flex: 1;
    }
  }

  @include for-tablet-and-up {
    max-width: 480px;
  }
}

.fullWidth {
  button {
    width: 100% !important;
  }
}

.gfiFullWidth {
  button {
    width: 100% !important;
    background-color: #112e4f !important;
  }

  button[disabled] {
    background-color: var(--GrayStrokeAndDisabled--translucent) !important;
  }
}

.gfiForm {
  height: auto;
  padding-bottom: 64px;

  input::placeholder {
    font-size: 14px;
  }

  fieldset {
    display: flex;
    align-items: center;

    label {
      margin-top: 0 !important;
      margin-right: 16px;
    }
  }
}

.nameContainer {
  display: flex;
  justify-content: space-evenly;

  .name {
    min-width: 100px;
    flex: 1 1 0;
  }

  @include for-phone-only {
    flex-direction: column;
    gap: var(--Space-16);
  }
}

.referrer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.gfiReferrer {
  position: relative;

  input:disabled {
    cursor: not-allowed;
    color: #9f9f9f;
    background-color: #e5e5e5;
    border-color: var(--GrayStrokeAndDisabled--translucent);
  }
}

.gfiReferrerInfoIcon {
  position: absolute;
  top: 2px;
  left: 90px;

  @include for-tablet-and-up {
    left: 102px;
  }
}

.gfiReferrerInfoTooltip {
  background-color: black !important;
  color: var(--White) !important;
  z-index: 999;
  box-shadow: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  padding: 12px !important;
  font-size: 14px !important;

  [data-placement*='top']::before {
    border-top-color: var(--GrayPrimary--opaque) !important;
  }
  [data-placement*='right']::before {
    border-right-color: var(--GrayPrimary--opaque) !important;
  }
  [data-placement*='bottom']::before {
    border-bottom-color: var(--GrayPrimary--opaque) !important;
  }
  [data-placement*='left']::before {
    border-left-color: var(--GrayPrimary--opaque) !important;
  }
}

.gfiRadioButton {
  input:checked ~ aside {
    border: 5px solid #112e4f !important;
  }
}

.gfiCheckbox {
  input:checked ~ svg {
    background-color: #112e4f !important;
  }
}

.flexStretch {
  flex: 1;
}

.agreeterm {
  font-weight: 400;
  font-size: 14px;
  a {
    color: var(--BrandForest);
  }
  padding-top: 3px;
}

.error {
  color: var(--BrandSunburn);
}

.gfiLabel {
  font-size: 14px;
  font-weight: normal;
  a {
    color: black;
  }
}

.gfiInfoMessage {
  [class*='InfoMessage'] {
    padding-bottom: 4px;
  }
}
