@import '~ethos-design-system/src/components/Media/Media.scss';

.boxes {
  @include for-laptop-and-up {
  }
  @include for-desktop-only {
    min-width: 566px;
  }
}

.values {
  min-width: 275px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.perks {
  font-size: 18px;
  line-height: 26px; /* 144.444% */
  span {
    font-weight: 500;
  }
}

.imageContainer {
  z-index: 2;
}

.image {
  @include for-laptop-and-up {
    width: auto;
    margin-right: -8px;
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    margin-bottom: -30px;
  }
}

$overlay: 410px;
$mobileOverlay: 320px;

.grayCurveOverlay {
  position: absolute;
  z-index: 1;
  width: $mobileOverlay;
  height: $mobileOverlay;
  bottom: -10px;
  right: -10px;
  transform: rotate(90deg);
  @include for-laptop-and-up {
    width: $overlay;
    height: $overlay;
  }
  .inset {
    width: $mobileOverlay;
    height: $mobileOverlay;
    overflow: hidden;
    position: relative;
    @include for-laptop-and-up {
      width: $overlay;
      height: $overlay;
    }
    &:before {
      content: '';
      top: 0;
      right: 0;
      box-shadow: 90px -90px 0 0 rgba(42, 73, 69, 0.2);
      display: block;
      width: 200%;
      height: 200%;
      position: absolute;
      border-radius: 50%;
      @include for-laptop-and-up {
        box-shadow: 180px -180px 0 0 rgba(42, 73, 69, 0.2);
      }
    }
  }
}

.sofiFooter {
  font-size: 12px;
  line-height: 1.25;

  @include for-laptop-and-up {
    font-size: 13.5px;
    line-height: 1.1851851852;
  }
}
