.fullBleed {
  padding-bottom: var(--Space-32);
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
}

.fullBleed,
.fullBleed .backgroundImage {
  @media (min-width: 768px) {
    height: 729px;
  }
}
