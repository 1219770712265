@import '~ethos-design-system/src/components/Media/Media.scss';

.foregroundImage {
  width: 250px;
  @include for-tablet-and-laptop {
    &.trust {
      width: 200px;
    }
  }
  @include for-laptop-only {
    width: 300px;
  }
  @include for-desktop-only {
    width: 300px;

    @media (min-width: 1280px) {
      width: 350px;
    }
  }
}
