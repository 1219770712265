.bgCypress1 {
  background-color: var(--BrandCypress);
  color: var(--White);
}

.bgCypress2 {
  background-color: #438981;
  color: var(--White);
}

.bgCypress3 {
  background-color: #82b0ab;
  color: var(--White);
}

.bgCypress4 {
  background-color: #c0d8d5;
  color: var(--White);
}

.bgCypress5 {
  background-color: #dae7e6;
  color: var(--White);
}

.bgCypress6 {
  background-color: #f3f7f7;
  color: var(--White);
}

.bgLime1 {
  background-color: var(--BrandLime);
  color: var(--Black);
}

.bgLime2 {
  background-color: #f3febf;
  color: var(--Black);
}

.bgLime3 {
  background-color: #f9ffdf;
  color: var(--Black);
}

.bgLime4 {
  background-color: #fefff9;
  color: var(--Black);
}

.bgMarigold1 {
  background-color: #ffc24d;
  color: var(--Black);
}

.bgMarigold2 {
  background-color: #ffd17a;
  color: var(--Black);
}

.bgMarigold3 {
  background-color: #ffe0a6;
  color: var(--Black);
}

.bgMarigold4 {
  background-color: #fff0d3;
  color: var(--Black);
}

.bgMarigold5 {
  background-color: #fff8eb;
  color: var(--Black);
}

.bgMarigold6 {
  background-color: #fffcf6;
  color: var(--Black);
}

.bgSkyblue1 {
  background-color: #4087f4;
  color: var(--White);
}

.bgSkyblue2 {
  background-color: #70a5f7;
  color: var(--White);
}

.bgSkyblue3 {
  background-color: #9fc3fa;
  color: var(--White);
}

.bgSkyblue4 {
  background-color: #cfe1fc;
  color: var(--White);
}

.bgSkyblue5 {
  background-color: #e2edfd;
  color: var(--White);
}

.bgSkyblue6 {
  background-color: #f6f9ff;
  color: var(--White);
}

.bgTruffle1 {
  background-color: #d8d4b2;
  color: var(--Black);
}

.bgTruffle2 {
  background-color: #e1dfc5;
  color: var(--Black);
}

.bgTruffle3 {
  background-color: #ebe9d8;
  color: var(--Black);
}

.bgTruffle4 {
  background-color: #f5f4ec;
  color: var(--Black);
}

.bgTruffle5 {
  background-color: #f9f9f3;
  color: var(--Black);
}

.bgTruffle6 {
  background-color: #fdfdfb;
  color: var(--Black);
}

.bgSpace1 {
  background-color: var(--GrayPrimary);
  color: var(--White);
}

.bgSpace2 {
  background-color: #535354;
  color: var(--White);
}

.bgSpace3 {
  background-color: #939598;
  color: var(--White);
}

.bgSpace4 {
  background-color: #dfdfdf;
  color: var(--White);
}

.bgSpace5 {
  background-color: #f4f4f4;
  color: var(--White);
}

.bgSpace6 {
  background-color: #ffffff;
  color: var(--White);
}

.bgMoss {
  background-color: var(--BrandMoss);
  color: var(--BrandForest);
}

.bgForest {
  background-color: var(--BrandForest);
  color: var(--BrandSalamander);
}

.bgButtercup {
  background-color: var(--BrandButtercup);
  color: var(--BrandSalamander);
}

.bgDuckegg {
  background-color: var(--BrandDuckegg);
  color: var(--GrayPrimary--translucent);
}

.bgWhite {
  background-color: var(--White);
  color: var(--GrayPrimary--translucent);
}

.bgGray1 {
  background-color: var(--GrayLightHover--opaque);
}

.bgGray {
  background-color: var(--GrayLightHover--opaque);
}

.bgNumberHeader {
  background: linear-gradient(27deg, #daefe3 8.28%, #c0d8d5 91.72%);
}

.bgSand {
  background-color: var(--BrandSand);
}

.bgCypressLight {
  background-color: #ecf6f0;
}

.bgSalamanderLight {
  background-color: #fff2e3;
}

.bgTruffleLight {
  background-color: #f5f4ec;
}

.bgDuckeggLight {
  background-color: #f6f9ff;
}

.bgGreylight {
  background-color: #f4f4f4;
}

.bgLightBlue {
  background: rgba(222, 235, 255, 0.6);
}

.bgGfiBlue {
  background: rgba(17, 46, 79, 1);
}
